import React from "react";
import PropTypes from "prop-types";
import styles from "./Footer.module.scss";
import Logo from "../../assets/logo-white.svg";

const Footer = (props) => {
  return (
    <div className={`${styles.footer} section dark`}>
      <div className={`wrapper ${styles.card}`}>
        <button
          className={styles.logo}
          onClick={() => {
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
        >
          <img src={Logo} alt="CTF Latam" />
        </button>

        <div className={styles.info}>
          <p className={styles.bold}>Contacto</p>
          <p>info@ctflatam.com</p>
          <p>Buenos Aires, Argentina</p>
        </div>
      </div>
      <div className={styles.wrapper}>
        <p className={styles.copyright}>
          Todos los derechos reservados © {new Date().getFullYear()}
        </p>
      </div>
    </div>
  );
};

Footer.propTypes = {};

export default Footer;
