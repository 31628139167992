import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styles from "./Header.module.scss";
import Logo from "../../assets/logo-big-light.svg";

const Header = () => {
  const [headerIsSmall, setHeaderIsSmall] = useState(false);
  const [navMenuOpen, setNavMenuOpen] = useState(false);

  const handleScrollTo = (id) => {
    const scrollTop = document.querySelector(id).offsetTop;
    window.scrollTo({ top: scrollTop - 70, behavior: "smooth" });

    setNavMenuOpen(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      setHeaderIsSmall(window.scrollY > 100);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleOpenNavMenu = () => {
    setNavMenuOpen((prev) => !prev);
  };

  return (
    <div className={`${styles.header} ${headerIsSmall ? styles.small : ""}`}>
      <div className={`wrapper ${styles.wrapper}`}>
        <button
          className={styles.logo}
          onClick={() => {
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
        >
          <img src={Logo} className={styles.logoImg} alt="CTF Latam" />
        </button>

        <button
          className={`${styles.pancake} ${navMenuOpen ? styles.active : ""}`}
          onClick={handleOpenNavMenu}
        >
          <div className={styles.pancakeBox}>
            <span></span>
            <span></span>
            <span></span>
          </div>
          <span>Menú</span>
        </button>

        <nav className={`${styles.nav} ${navMenuOpen ? styles.active : ""}`}>
          <ul>
            <li onClick={() => handleScrollTo("#us")}>
              <button>Nosotros</button>
            </li>
            <li onClick={() => handleScrollTo("#services")}>
              <button>Servicios</button>
            </li>
            <li onClick={() => handleScrollTo("#clients")}>
              <button>Clientes</button>
            </li>
            <li onClick={() => handleScrollTo("#contact")}>
              <button className={styles.navHighlight}>¡Contactanos!</button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

Header.propTypes = {};

export default Header;
