import React from "react";
import PropTypes from "prop-types";
import styles from "./Services.module.scss";
import { handleScrollToContact } from "../../utils";

const Service = ({ title, align = "left", graphic, body }) => {
  return (
    <div
      className={`${styles.service} ${align === "left" ? styles.left : styles.right
        }`}
    >
      <div className={styles.info}>
        <h3>{title}</h3>

        <p className={styles.body}>
          {body}
        </p>

        <button onClick={handleScrollToContact} className="button">
          Quiero más info
        </button>
      </div>
      <div className={styles.graphic}>
        <img src={graphic} alt="" height={300} />
      </div>
    </div>
  );
};

Service.propTypes = {};

export default Service;
