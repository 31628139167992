import React from "react";
import PropTypes from "prop-types";
import styles from "./Us.module.scss";

import CodeIcon from "../../assets/code-icon.svg";

const Us = (props) => {
  return (
    <div className={`${styles.us} section dark`} id="us">
      <img src={CodeIcon} alt="" className={styles.bgIcon} />

      <div className={`wrapper`}>
        <h2 className={`sectionTitle`}>Nosotros</h2>
        <p className={styles.body}>
          Somos una empresa que se especializa en crear soluciones tecnológicas
          personalizadas para empresas y organizaciones. Esto implica el diseño,
          desarrollo, implementación y mantenimiento de aplicaciones y sistemas
          informáticos . Además, ofrecemos servicios de staff augmentation,
          proporcionando personal técnico calificado para complementar los
          equipos de desarrollo existentes en tu empresas.
        </p>
      </div>
    </div>
  );
};

Us.propTypes = {};

export default Us;
