import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import styles from "./Clients.module.scss";

import VsDode from "../../assets/clients/vscode.png";
import Node from "../../assets/clients/node.png";
import Js from "../../assets/clients/Js.png";
import Angular from "../../assets/clients/Angular.png";
import Vue from "../../assets/clients/Vue.png";
import Sql from "../../assets/clients/sql.png";

import Slider from "react-slick";

const CLIENTS = [
  {
    title: ".Net",
    image: VsDode,
  },
  {
    title: "NodeJs",
    image: Node,
  },
  {
    title: "JavaScript",
    image: Js,
  },
  {
    title: "Angular",
    image: Angular,
  },
  {
    title: "Vue",
    image: Vue,
  },
  {
    title: "Sql Server",
    image: Sql,
  },
];

const Clients = (props) => {
  const [current, setCurrent] = useState(0);

  let sliderRef = useRef(null);

  const settings = {
    arrows: false,
    swipeToSlide: false,
    draggable: false,
    infinite: true,
    dots: false,
    slidesToShow: 4,
    autoplay: true,
    autoplaySpeed: 1500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  return (
    <div className={`${styles.clients} section dark`} id="clients">
      <div className="wrapper">
        <h2 className={`sectionTitle`}>Casos de éxito</h2>

        <div className={styles.currentClient}>
          <p className={styles.body}>
            Hemos logrado desarrollar soluciones para empresas que están al
            frente de la informática, como ejemplo PWC en la cual realizamos la
            modificación de los procesos de deducción de impuestos en diferentes
            productos de la empresa. En Tango, desarrollamos el rebuild del ERP
            principal de la empresa, migrando de la tecnología Delphi a .NET y
            React, esto y mucho mas esta al alcance de tus manos con nosotros.
          </p>
        </div>

        <div className={styles.slider}>
          <Slider
            {...settings}
            ref={(slider) => {
              sliderRef = slider;
            }}
          >
            {CLIENTS.map((client, index) => (
              <div className={styles.clientSlide}>
                <img src={client.image} alt={client.title} />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

Clients.propTypes = {};

export default Clients;
