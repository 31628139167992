import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import styles from "./Contact.module.scss";
import axios from "axios";
import Swal from "sweetalert2";

export const objectToFormData = (obj) => {
  const pairs = Object.entries(obj);
  const data = new FormData();
  pairs.forEach((pair) => data.append(pair[0], pair[1]));
  return data;
};

const Contact = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [disableInput, setDisableInput] = useState(false);

  const refs = {
    name: useRef(),
    email: useRef(),
    phone: useRef(),
    message: useRef(),
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (disableInput || isLoading) return;

    setIsLoading(true);

    const body = {
      nombre: refs.name.current.value.trim(),
      correo: refs.email.current.value.trim(),
      mensaje: refs.message.current.value.trim(),
    };

    if (refs.phone.current.value.trim() !== "") {
      body.telefono = refs.phone.current.value.trim();
    }

    try {
      const response = await axios({
        method: "POST",
        url: `https://ctflatam.com/api/send-mail.php`,
        data: objectToFormData(body),
      });

      if (response.data.status !== "ok") throw new Error();

      await Swal.fire({
        title: "Mensaje enviado!",
        text: "Se ha enviado correctamente!",
        icon: "success",
      });

      setDisableInput(true);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      await Swal.fire({
        title: "Error al enviar",
        text: "Por favor intente nuevamente",
        icon: "error",
      });
    }
  };

  return (
    <div className={`section`} id="contact">
      <div className={`wrapper`}>
        <h2 className={`sectionTitle`}>Contactanos</h2>

        <div className={styles.container}>
          <form className={styles.form} onSubmit={handleSubmit}>
            <div className={styles.formItem}>
              <label>Nombre</label>
              <input ref={refs.name} type="text" name="nombre" required />
            </div>

            <div className={styles.formItem}>
              <label>Email</label>
              <input ref={refs.email} type="email" name="email" required />
            </div>

            <div className={styles.formItem}>
              <label>
                Telefono <span>(opcional)</span>
              </label>
              <input ref={refs.phone} type="phone" name="telefono" />
            </div>

            <div className={styles.formItem}>
              <label>Mensaje</label>
              <textarea ref={refs.message} name="mensaje" required></textarea>
            </div>

            <button disabled={disableInput || isLoading} className="button">
              {isLoading ? "Enviando..." : "Enviar"}
            </button>
          </form>

          <div className={styles.map}>
            <div className={styles.mapContainer}>
              <iframe
                title="map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d105073.45340273721!2d-58.51569885718409!3d-34.61565476971351!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcca3b4ef90cbd%3A0xa0b3812e88e88e87!2sBuenos%20Aires%2C%20Cdad.%20Aut%C3%B3noma%20de%20Buenos%20Aires!5e0!3m2!1ses!2sar!4v1721696330604!5m2!1ses!2sar"
                width="600"
                height="370"
                style={{ border: 0 }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Contact.propTypes = {};

export default Contact;
