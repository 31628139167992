import React from "react";
import PropTypes from "prop-types";
import styles from "./Services.module.scss";
import Service from "./Service";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';

import SoftwareFactoryGraphic from "../../assets/services/software-factory.svg";
import ConsultingGraphic from "../../assets/services/consulting.svg";
import StaffingGraphic from "../../assets/services/staffing.svg";
import manos from "../../assets/services/manos.png"
import seleccion from "../../assets/services/seleccion.png"
import work from "../../assets/services/work.png"

const Services = (props) => {
  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <div className={`section`} id="services">
      <div className={`wrapper`}>
        <h2 className={`sectionTitle`}>Servicios</h2>

        <div data-aos="fade-right" data-aos-duration="1500">
          <Service
            title="Desarrollos a medida"
            graphic={work}
            body={
              "Hacemos realidad tus ideas con tecnologia de ultima, desarrollamos tanto aplicaciones, " +
              "como paginas WEB, como sistemas de gestion. Definiendo en conjunto su alcance para tener tu " +
              "producto acorde a las necesidades planteadas."
            }
          />
        </div>

        <div data-aos="fade-left" data-aos-duration="1500">
          <Service
            title="Staff Augmentation"
            align="right"
            graphic={seleccion}
            body={`Somos tu brazo extendido en tecnología, ofrecemos servicios de staff augmentation, proporcionando personal técnico calificado para complementar los equipos de desarrollo existentes en las empresas, cubriendo así la demanda temporal o permanente de habilidades específicas, tanto para desarrollo backend, frontend o ambos, como también perfiles de gestión como project managers, analistas funcionales, testers y diseñadores.`}
          />
        </div>

        <div data-aos="fade-right" data-aos-duration="1500">
          <Service
            title="Consultoría"
            graphic={manos}
            body={`Contamos con especialistas y aliados en el mundo IT para poner en marcha nuevas implementaciones con tecnologias y frameworks de vanguardia.`}
          />
        </div>
      </div>
    </div>
  );
};

Services.propTypes = {};

export default Services;
